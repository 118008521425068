import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Title = makeShortcode("Title");
const DoDontExample = makeShortcode("DoDontExample");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{` This component will be deprecated in a future release. Please use
the `}<a parentName="p" {...{
          "href": "/components/DoDontRow"
        }}>{`DoDontRow`}</a>{` and `}<a parentName="p" {...{
          "href": "/components/DoDontRow"
        }}>{`DoDont`}</a>{`
component instead.`}</p>
    </InlineNotification>
    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<DoDontExample>`}</inlineCode>{` component will generally need to be placed inside `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{`
and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components to align them to the grid at the correct size.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <Title mdxType="Title">Image</Title>
        <DoDontExample type="do" captionTitle="Caption title" caption="Caption" mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7s0SAAD//EABYQAQEBAAAAAAAAAAAAAAAAACABEf/aAAgBAQABBQIZD//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABkQAAIDAQAAAAAAAAAAAAAAAAERABAgMf/aAAgBAQABPyGKkD3R/9oADAMBAAIAAwAAABAjzzz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAbEAACAQUAAAAAAAAAAAAAAAABEQAQIEFh8P/aAAgBAQABPxABx6MA4bnDt//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Alt text",
              "title": "Alt text",
              "src": "/static/151c08285741565650307880afc0c3cc/c3319/light-theme.jpg",
              "srcSet": ["/static/151c08285741565650307880afc0c3cc/69549/light-theme.jpg 288w", "/static/151c08285741565650307880afc0c3cc/473e3/light-theme.jpg 576w", "/static/151c08285741565650307880afc0c3cc/c3319/light-theme.jpg 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </DoDontExample>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <Title mdxType="Title">Text</Title>
        <DoDontExample type="dont" aspectRatio="1:1" color="dark" captionTitle="Caption title" caption="Caption" text="This is some text" mdxType="DoDontExample" />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <Title mdxType="Title">Video</Title>
        <DoDontExample type="do" caption="Caption" captionTitle="Caption title" mdxType="DoDontExample">
          <Video title="Video example" vimeoId="310583077" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <Title mdxType="Title">Aspect ratios</Title>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="4:3" text="4:3" mdxType="DoDontExample" />
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="16:9" text="16:9" mdxType="DoDontExample" />
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="2:1" text="2:1" mdxType="DoDontExample" />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="9:16" text="9:16" mdxType="DoDontExample" />
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="1:2" text="1:2" mdxType="DoDontExample" />
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="3:4" text="3:4" mdxType="DoDontExample" />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" text="1:1" mdxType="DoDontExample" />
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Image</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample type="do" captionTitle="Caption title" caption="Caption">

![Alt text](images/light-theme.jpg)

</DoDontExample>
`}</code></pre>
    <Title mdxType="Title">Text</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample
  type="dont"
  aspectRatio="1:1"
  color="dark"
  captionTitle="Caption title"
  caption="Caption"
  text="This is some text"
/>
`}</code></pre>
    <Title mdxType="Title">Video</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample type="do" caption="Caption" captionTitle="Caption title">
  <Video vimeoId="310583077" />
</DoDontExample>
`}</code></pre>
    <Title mdxType="Title">Aspect ratios</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample aspectRatio="4:3" text="4:3" />
<DoDontExample aspectRatio="16:9" text="16:9" />
<DoDontExample aspectRatio="2:1" text="2:1" />
<DoDontExample aspectRatio="9:16" text="9:16" />
<DoDontExample aspectRatio="1:2" text="1:2" />
<DoDontExample aspectRatio="3:4" text="3:4" />
<DoDontExample aspectRatio="1:1" text="1:1" />
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`child node, expects a markdown image or `}<inlineCode parentName="td">{`<Video>`}</inlineCode>{` component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text to display inside the component instead of an image or video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`caption`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`caption`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`captionTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`caption title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`dont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`set to `}<inlineCode parentName="td">{`do`}</inlineCode>{` to show green check, otherwise shows pink X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1:1`}</inlineCode>{`,`}<inlineCode parentName="td">{`1:2`}</inlineCode>{`,`}<inlineCode parentName="td">{`2:1`}</inlineCode>{`,`}<inlineCode parentName="td">{`3:4`}</inlineCode>{`,`}<inlineCode parentName="td">{`4:3`}</inlineCode>{`,`}<inlineCode parentName="td">{`16:9`}</inlineCode>{`,`}<inlineCode parentName="td">{`9:16`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      